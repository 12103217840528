export default {
    convertMsToHM(millis) {
        let sec = Math.ceil(millis / 1000);
        let min = Math.floor(sec / 60);
        let hrs = Math.floor(min / 60);
        let days = Math.floor(hrs / 24);
        sec %= 60;
        min %= 60;
        hrs %= 24;
        let res = '';
        if (days) res = `${days}d `;
        if (hrs) res += `${hrs}h `;
        if (min) res += `${min}m `;
        if (sec || !res) res += `${sec}s`;
      
        return res;
    },
    onCustomFiltered(allData, rdata, filter) {
        let data = allData.filter(item => item.mName.toString().toLowerCase().includes(filter.toString().toLowerCase()))
        if (data.length > 0 && filter != '') {
            rdata = data
        }
        else if (filter == '') {
            rdata = []
            rdata = allData
        }
        else {
            rdata = []
        }
        return rdata
    },
    downloadCSV(data) {
        const rows = [];
        const headers = Object.keys(data[0]);
      
        // Add headers to CSV
        rows.push(headers.join(','));
      
        // Add data to CSV
        data.forEach((item) => {
          const values = headers.map((header) => {
            const value = item[header];
            return JSON.stringify(value);
          });
          rows.push(values.join(','));
        });
      
        // Convert to CSV and download
        const csv = rows.join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'ExampleBulkImportFile.csv';
        link.click();
      }
}