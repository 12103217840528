<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <px-card class="p-3">
          <b-row>
            <b-col>
              <b-row> Status: </b-row>
              <b-row>
                <b v-if="incedent_details.status" class="text-success"
                  ><i class="fa fa-dot-circle-o"></i> Resolved</b
                >
                <b v-else class="text-danger"
                  ><i class="fa fa-dot-circle-o"></i> Ongoing</b
                >
              </b-row>
            </b-col>
            <b-col>
              <b-row>Started at:</b-row>
              <b-row>
                {{ changeDateFormate(incedent_details.createdAt) }}
              </b-row>
            </b-col>
            <b-col>
              <b-row>Duration:</b-row>
              <b-row>
                {{ convertMsToHM(incedent_details.duration) }}
              </b-row>
            </b-col>
          </b-row>
        </px-card>
        <!-- HTTP Details UI Start  -->
        <div v-if="incedent_details.mtype == 1" class="col-md-12 p-5 box">
          <b-row> Details </b-row>
          <hr />
          <b-row class="mt-2"> Request: </b-row>
          <b-row class="mt-3">
                 Method :- <span class="badge badge-flat badge-success ml-3">{{incedent_details.detail.reqObj.method}}</span>
            </b-row>
          <b-row class="mt-2">
            <b-input :value="incedent_details.detail.reqObj.uri" disabled />
          </b-row>
          <b-row class="mt-2"> Reason: </b-row>
          <b-row class="mt-2">
            <b-textarea :value="incedent_details.reason" disabled/>
          </b-row>
        </div>
        <!-- HTTP Details UI End  -->

        <!-- Keyword Details UI Start  -->
        <div v-else-if="incedent_details.mtype == 2" class="col-md-12 p-5 box">
          <b-row> Details </b-row>
          <hr />
          <b-row class="mt-2"> Request: </b-row>
          <b-row class="mt-2">
                 Method : <span class="badge badge-flat badge-success ml-3">{{incedent_details.detail.reqObj.method}}</span>
          </b-row>
          <b-row  v-if="incedent_details.detail.resHeaders">
            <b-row class="mt-2">
              Headers : 
            </b-row>
            <b-row class="col-md-12 mt-2">
              <span class="col-md-12" v-for="[key, value] of Object.entries(incedent_details.detail.resHeaders)" :key="key">
                  <b-row class="col-md-12">
                          <code>{{key}} : {{value}}</code>
                  </b-row>
              </span>
            </b-row>
          </b-row>
          <b-row class="mt-2">
            Url : 
          </b-row>
          <b-row class="mt-2">
            <b-input :value="incedent_details.detail.reqObj.uri" disabled />
          </b-row>
          <b-row class="mt-2"> Reason: </b-row>
          <!-- <b-row class="mt-2">
            <b-textarea  rows="5" :value="incedent_details.detail.body" disabled/>
          </b-row> -->
          <b-row class="mt-2">
            <b-textarea :value="incedent_details.reason" disabled/>
          </b-row>
          <b-row class="mt-2">
            <span class="link_txt" @click="download('htm.txt', incedent_details.detail.body)">Download file</span>
          </b-row>
        </div>
        <!-- Keyword Details UI End  -->

        <!-- Ping Details UI Start  -->
        <div v-else-if="incedent_details.mtype == 3" class="col-md-12 p-5 box">
          <b-row> Details </b-row>
          <hr />
          <b-row class="mt-2"> Request: </b-row>
          <b-row class="mt-2">
            <b-input :value="incedent_details.detail.inputHost" disabled />
          </b-row>
          <b-row class="mt-2"> Reason: </b-row>
          <b-row>
            <b-textarea :value="incedent_details.reason" disabled/>
          </b-row>
        </div>
        <!-- Ping Details UI End  -->

        <!-- Port Details UI Start  -->
        <div v-else-if="incedent_details.mtype == 4" class="col-md-12 p-5 box">
          <b-row> Details </b-row>
          <hr />
          <b-row class="mt-2"> Request: </b-row>
          <b-row class="mt-2">
            <b-input :value="incedent_details.detail.host+':'+incedent_details.detail.port" disabled />
          </b-row>
          <b-row class="mt-2"> Reason: </b-row>
          <b-row class="mt-2">
            <b-textarea :value="incedent_details.reason" disabled/>
          </b-row>
        </div>
        <!-- Port Details UI End  -->

        <!-- HeartBeat Details UI Start  -->
        <div v-else-if="incedent_details.mtype == 5" class="col-md-12 p-5 box">
          <b-row> Details </b-row>
          <hr />
          <b-row class="mt-2"> Request: </b-row>
          <b-row class="mt-2">
            <b-input :value=request_uri disabled />
          </b-row>
          <b-row class="mt-2"> Reason: </b-row>
          <b-row class="mt-2">
            <b-textarea :value="incedent_details.reason" disabled/>
          </b-row>
          <b-row class="mt-2">Details : </b-row>
          <b-row>
            <div class="table-responsive datatable-vue text-left text-center">
              <b-table
                  show-empty
                  stacked="md"
                  :items="hertbeat_log_data"
                  :fields="tablefields"
                  disabled
                >
                <template #cell(user_agent)="data">
                    {{data.item.headers['user-agent']}}
                </template>
                <template #cell(createdAt)="data">
                    <span class="text-info">{{changeDateFormate(data.item.createdAt)}}</span>
                </template>
                </b-table>
            </div>
          </b-row>
        </div>
        <!-- HeartBeat Details UI End  -->
        <div v-else class="col-md-12 p-5 box">
          <b-row> Details </b-row>
          <hr />
          <b-row class="mt-2"> Reason: </b-row>
          <b-row>
            <b-textarea :value="incedent_details.reason" disabled/>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import moment from "moment";
import Config from "../../services/config";
import DashboardController from "../../services/dashboardController";
import HelperFunction from "../../services/helperFunction"
export default {
  name: "IncedentDetails",
  props: { incedent_details: Object },
  data() {
    return {
      monitor_type_opt: [
        { value: null, text: "Please Select" },
        { value: "1", text: "HTTP(s)" },
        { value: "2", text: "Keyword" },
        { value: "3", text: "Ping" },
        { value: "4", text: "Port" },
        { value: "5", text: "Heartbeat" },
        { value: '6', text:'Page Speed' },
        { value: '7', text:'Page Screenshot' }
      ],
      request_uri:'',
      hertbeat_log_data : [],
      tablefields:[
      { key: "ip", label: "IP", sortable: true },
      { key: "user_agent", label: "UserAgent", sortable: true },
      { key: "createdAt", label: "Last Ping On", sortable: true },
      ]
    };
  },
  mounted(){
    if(this.incedent_details.mtype == 5){
        this.request_uri = Config.hertbeatUrl + this.incedent_details.mId
        this.getDetails(this.incedent_details.mId)
    }
  },
  methods: {
    async getDetails(id){
        this.hertbeat_log_data = []
        var response = await DashboardController.keywordLog(id);
        if(response.result){
            this.hertbeat_log_data = response.data
        }
    },
    changeDateFormate(d) {
      return moment(d).format("D/M/YYYY, HH A");
    },
    convertMsToHM(millis) {
      return HelperFunction.convertMsToHM(millis)
    },
    download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    }
  },
};
</script>



<style scoped>
.box {
  background: #e6e6e6;
  overflow: auto;
}
</style>